/**
 * Client-side counterpart to locals.formatDate().
 *
 * @param {*} s a Date, string, number as milliseconds, null, or undefined.
 * @returns as "25/01/2024, 13:26"
 */
export const formatDateJS = function (s) {
  if (s) {
    let dt = undefined
    if (typeof s === "number") {
      dt = new Date(s)
    } else if (typeof s === "string") {
      try {
        dt = new Date(s)
      } catch {
        // meh
      }
    } else if (s instanceof Date) {
      dt = s
    }

    // hide 1970...
    if (dt && dt.getTime() > 0) {
      return dt.toLocaleDateString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      })
    }
  }
  return "-"
}
