var Gauge = require("./gauge")

$(function () {
  const colourToValue = (value) => {
    if (value < 0) {
      return "#ddd"
    }

    if (value < 2) {
      return "#E0312B"
    }

    if (value < 5) {
      return "#FF801A"
    }

    if (value < 7) {
      return "#FCC82B"
    }

    if (value < 9) {
      return "#8DC340"
    }

    return "#2FB27D"
  }

  $(".gauge").each(function (i, el) {
    let value = $(el).data("value")
    let lower = $(el).data("lower") || 0
    let upper = $(el).data("upper") || 0
    let showErrorBars = $(el).data("error-bars") || false
    let label = $(el).data("label") || value
    let valueHandleRadius = $(el).data("handle-radius") || 6

    var g = Gauge(el, {
      label: (value) => label,
      value: 0,
      color: value ? colourToValue : (value) => "#ddd",
      min: 0,
      max: 10,
      lower: lower,
      upper: upper,
      showErrorBars: showErrorBars,
      dialStartAngle: 120,
      dialEndAngle: 60,
      valueHandleRadius: valueHandleRadius
    })

    g.setValueAnimated(value, 1)
  })
})
