// Upvote controls

$(function () {
  $("button.js-vote-add").on("click tap", function (event) {
    event.preventDefault()

    const button = $(event.target).parents("button")
    const container = $(button).parents(".js-vote")
    const counter = container.find(".js-vote-count")

    let id = $(button).data("review-id")
    fetch(`/upvote/${id}`, { method: "POST" })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data)
        let newCount =
          data.upvoteCount === 0 ? "0" : data.upvoteCount || counter.text()
        counter.text(newCount)

        if ("can-revoke" === data.nextAction) {
          container.find(".js-vote-add").addClass("hidden")
          container.find(".js-vote-remove").removeClass("hidden")
        }
      })
      .catch((err) => console.warn(err))
  })

  $("button.js-vote-remove").on("click tap", function (event) {
    event.preventDefault()

    const button = $(event.target).parents("button")
    const container = $(button).parents(".js-vote")
    const counter = container.find(".js-vote-count")

    let id = $(button).data("review-id")
    fetch(`/upvote/${id}`, { method: "DELETE" })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data)
        let newCount =
          data.upvoteCount === 0 ? "0" : data.upvoteCount || counter.text()
        counter.text(newCount)

        if ("can-upvote" === data.nextAction) {
          container.find(".js-vote-add").removeClass("hidden")
          container.find(".js-vote-remove").addClass("hidden")
        }
      })
      .catch((err) => console.warn(err))
  })
})
