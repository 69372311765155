/* Admin screen - toggle moderation status on reviewers. */

const { formatDateJS } = require("./utils")

$(function () {
  $("input.js-moderator-toggle").on("change", (e) => {
    e.preventDefault()

    let toggle = $(e.target)
    let id = toggle.data("reviewer-id")
    if (!id) {
      return false
    }

    let row = $(toggle).parents("tr.js-reviewer-row")

    let isNowChecked = toggle.prop("checked")
    let message = isNowChecked
      ? `Enabling this will suppress future reviews from this individual. Proceed?`
      : `Disabling this will allow future reviews from this individual to be accepted. Proceed?`
    let proceed = window.confirm(message)
    if (proceed) {
      fetch("/admin/update", {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id: id, isBlocked: isNowChecked })
      })
        .then((response) => response.json())
        .then((data) => {
          // data is a ReviewerUpdateResponse
          let lm = data?.lastModified
          if (row && lm) {
            let sp = $(row).find(".js-reviewer-last-modified")
            sp.text(formatDateJS(lm))
          }
        })
        .catch((err) => {
          // error, put the checkbox back as it was
          toggle.prop("checked", !isNowChecked)
          console.warn(err)
        })
    } else {
      // do not proceed, put the checkbox back as it was
      toggle.prop("checked", !isNowChecked)
    }
  })
})

$(function () {
  $("a.js-admin-edit-comment").on("click", function (e) {
    e.preventDefault()

    let target = $(e.target)
    let currentState = target.data("state")
    let reviewer = target.data("reviewer")
    let urlId = target.data("url-id")

    if (reviewer && urlId) {
      // post back an AdminEditReviewRequest
      let nextState = !currentState
      fetch("/admin/edit", {
        method: "POST",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          reviewer: reviewer,
          urlId: urlId,
          suppress: nextState
        })
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          window.location.reload() // crude
        })
        .catch((err) => {
          console.warn(err)
        })
    }
  })
})
